<template>
  <div>
    <!-- SECTION modal-flightv2-show-info-trip -->
    <BModal
      :id="`modal-flightv2-show-info-trip`"
      :title="$t('flight.flightInfo')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-25 px-lg-1"
      centered
      no-close-on-backdrop
      size="lg"
      @show="handleShowModal"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100 mr-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
      </template>

      <IAmOverlay
        :loading="loading"
        :spinner-variant="'info'"
      >
        <b-card
          v-if="!isEmpty(resultTripInfo)"
          class="mb-50"
          body-class="p-1 d-flex-center"
        >
          <p class="d-flex-center font-weight-bolder mb-0 flex-wrap">
            <span class="mr-75 font-medium-2">{{ `${resolveAirlineFlightNumber(resultTripInfo[0].airline, resultTripInfo[0].flightNumber)}` }}</span>
            <span class="mr-75 font-medium-2">{{ convertISODateTime(resultTripInfo[0].departureDate).dayMonth }}</span>
            <span class="mr-75 font-medium-2">{{ `${resultTripInfo[0].departure}${resultTripInfo[0].arrival}` }}</span>
            <span class="mr-75 font-medium-2">{{ convertISODateTime(resultTripInfo[0].departureDate, resultTripInfo[0].departureTimezone).hourMin }} {{ convertISODateTime(resultTripInfo[0].arrivalDate, resultTripInfo[0].arrivalTimezone).hourMin }}</span>
            <span
              v-if="resultTripInfo[0].airCraft"
              class="mr-75 font-medium-2"
            >{{ `| ${resultTripInfo[0].airCraft ? resultTripInfo[0].airCraft : ''}` }}</span>
            <span
              v-if="resultTripInfo[0].operating && resultTripInfo[0].operating === 'BL'"
              class="mr-75 font-medium-2 text-danger"
            >
              PACIFIC AIRLINES
            </span>
          </p>
        </b-card>

        <b-alert
          :show="!(resultTripInfo && resultTripInfo.length && resultTripInfo[0].isDomestic)"
          variant="warning"
          class="px-2 py-50 my-50"
        >
          <em>{{ $t('flight.fareNotIncludeTax') }}</em>
        </b-alert>

        <b-card
          class="mb-0"
          no-body
        >
          <BTableLite
            v-if="!isEmpty(resultTripInfo)"
            bordered
            responsive
            small
            hover
            striped
            body-class="p-0"
            class="mb-1 rounded"
            thead-class="text-dark font-weight-bolder text-nowrap"
            tbody-class="text-dark text-nowrap"
            :fields="tableColumns"
            :items="resultTripInfo[0].bookingClassAvail"
          >
            <template
              v-for="(column, indexColumn) in tableColumns"
              v-slot:[`head(${column})`]="data"
            >
              <div
                :key="indexColumn"
              >
                <span v-if="data.column !== 'priceTemp'">{{ $t(`reservation.${data.column}`) }}</span>
                <span v-else>{{ $t(`reservation.${data.column}`) }} ({{ $t('flight.Adult') }})</span>
              </div>
            </template>
            <template #cell(classBooking)="{item}">
              <div class="text-center font-medium-3 font-weight-bolder">
                <span
                  v-if="item.airline && ['VU'].includes(item.airline)"
                  :class="!item.refundable ? 'text-danger' : '' "
                >
                  {{ item.code }}
                </span>
                <span v-else>{{ item.code }}</span>
              </div>
            </template>
            <template #cell(fareBasisCode)="{item}">
              <div class="text-center font-weight-bolder">
                <span class="text-danger">{{ getFareAdult(item.prices).fareBasisCode }}</span>
              </div>
            </template>
            <template #cell(availability)="{item}">
              <div
                class="text-center font-3 font-weight-bolder"
                :class="item.availability === 0 ? 'text-danger' : (item.availability > 0 && item.availability < 9) ? 'text-warning' : 'text-success'"
              >
                {{ item.availability }}
              </div>
            </template>
            <template #cell(priceTemp)="data">
              <p
                v-if="!isEmpty(data.item.prices) && getFareAdult(data.item.prices)"
                :id="`pax_priceTemp_${data.index}`"
                class="mb-0 font-weight-bolder"
              >
                <span v-if="['VN'].includes(resultTripInfo[0].airline) && resultTripInfo[0].isDomestic">{{ $t('flight.netFare') }}:</span> <span
                  v-if="['VN'].includes(resultTripInfo[0].airline)"
                  class="font-weight-bolder"
                >
                  {{ getFareAdult(data.item.prices).net }}
                </span>

                <span v-if="['VN'].includes(resultTripInfo[0].airline) && resultTripInfo[0].isDomestic">
                  -  {{ $t('flight.taxes') }}: <span class="font-weight-bolder">{{ getFareAdult(data.item.prices).tax }}</span>
                  -  {{ $t('flight.total') }}: <span class="text-warning font-weight-bolder">{{ getFareAdult(data.item.prices).fare }}</span>
                </span>

                <span v-if="resultTripInfo[0].airline === 'VU'">
                  {{ $t('flight.total') }}: <span class="text-warning font-weight-bolder">{{ getFareAdult(data.item.prices).fare }}</span>
                </span>
              </p>

              <b-tooltip
                :id="`tooltip-pax_priceTemp_${data.index}`"
                :target="`pax_priceTemp_${data.index}`"
                triggers="hover"
                variant="info"
                placement="top"
                boundary="window"
              >
                <div
                  :class="`font-italic text-white ${isMobileView ? 'font-small-1' : 'font-small-3'}`"
                >
                  {{ $t('flight.fareNotIncludeServiceFee') }}
                </div>
              </b-tooltip>

              <!-- <p
                v-for="(price, i) in item.prices"
                :key="i"
                class="mb-75 border-bottom"
              >
                {{ $t(`reservation.${price.paxType}`) }}({{ price.fareBasisCode }}): Giá: <span class="font-weight-bolder">{{ formatCurrency(price.net) }}</span> - Thuế, phí: <span class="font-weight-bolder">{{ formatCurrency(price.tax) }}</span> - Tổng: <span class="text-warning font-weight-bolder">{{ formatCurrency(price.fare) }}</span>
              </p> -->
            </template>
          </BTableLite>
        </b-card>
      </IAmOverlay>
    </BModal>
  </div>
</template>

<script>
import {
  BModal, BTableLite, BButton, BCard, BTooltip, BAlert,
} from 'bootstrap-vue'
import { ref, toRefs, watch } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import IAmOverlay from '@/components/IAmOverlay.vue'

import {
  formatCurrency,
  convertShortTrip,
  formatDateMonth,
  convertISODateTime,
  resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BCard,
    BTableLite,
    BButton,
    BTooltip,

    IAmOverlay,
    BAlert,
  },
  props: {
    segmentInfo: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { toastError } = useToast()
    const { segmentInfo } = toRefs(props)
    const resultTripInfo = ref([])
    const loading = ref(false)

    watch(() => segmentInfo.value, val => {
      if (val) {
        try {
          resultTripInfo.value = val.map(item => item.trips.map(t => t.segments.map(seg => seg))).flat(3)
        } catch (error) {
          console.error('Đã xảy ra lỗi trong quá trình xử lý:', error)

          toastError({
            title: 'Đã xảy ra lỗi trong quá trình xử lý, vui lòng liên hệ hỗ trợ!',
          })

          resultTripInfo.value = []
        }
      }
    }, { deep: true })

    watch(() => resultTripInfo.value, val => {
      if (!isEmpty(val)) {
        loading.value = false
      } else {
        loading.value = true
      }
    }, { deep: true })

    function handleShowModal() {
      resultTripInfo.value = []
    }

    const getFareAdult = fareArr => {
      const adult = fareArr.find(p => ['ADULT'].includes(p.paxType))
      // if (!adult) return undefined

      return {
        fareBasisCode: adult?.fareBasisCode ? adult.fareBasisCode : '-',
        net: adult?.net ? formatCurrency(adult.net) : '-',
        tax: adult?.tax ? formatCurrency(adult.tax) : '-',
        fare: adult?.fare ? formatCurrency(adult.fare) : '-',
      }
    }

    const tableColumns = ['classBooking', 'fareBasisCode', 'availability', 'priceTemp']

    return {
      loading,
      resultTripInfo,
      isEmpty,

      handleShowModal,
      formatCurrency,
      convertShortTrip,
      formatDateMonth,
      convertISODateTime,

      getFareAdult,
      tableColumns,
      resolveAirlineFlightNumber,
    }
  },
}
</script>
