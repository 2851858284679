var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-flightv2-show-info-trip",
      "title": _vm.$t('flight.flightInfo'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-25 px-lg-1",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100 mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading,
      "spinner-variant": 'info'
    }
  }, [!_vm.isEmpty(_vm.resultTripInfo) ? _c('b-card', {
    staticClass: "mb-50",
    attrs: {
      "body-class": "p-1 d-flex-center"
    }
  }, [_c('p', {
    staticClass: "d-flex-center font-weight-bolder mb-0 flex-wrap"
  }, [_c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s("".concat(_vm.resolveAirlineFlightNumber(_vm.resultTripInfo[0].airline, _vm.resultTripInfo[0].flightNumber))))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.resultTripInfo[0].departureDate).dayMonth))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s("".concat(_vm.resultTripInfo[0].departure).concat(_vm.resultTripInfo[0].arrival)))]), _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.resultTripInfo[0].departureDate, _vm.resultTripInfo[0].departureTimezone).hourMin) + " " + _vm._s(_vm.convertISODateTime(_vm.resultTripInfo[0].arrivalDate, _vm.resultTripInfo[0].arrivalTimezone).hourMin))]), _vm.resultTripInfo[0].airCraft ? _c('span', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(_vm._s("| ".concat(_vm.resultTripInfo[0].airCraft ? _vm.resultTripInfo[0].airCraft : '')))]) : _vm._e(), _vm.resultTripInfo[0].operating && _vm.resultTripInfo[0].operating === 'BL' ? _c('span', {
    staticClass: "mr-75 font-medium-2 text-danger"
  }, [_vm._v(" PACIFIC AIRLINES ")]) : _vm._e()])]) : _vm._e(), _c('b-alert', {
    staticClass: "px-2 py-50 my-50",
    attrs: {
      "show": !(_vm.resultTripInfo && _vm.resultTripInfo.length && _vm.resultTripInfo[0].isDomestic),
      "variant": "warning"
    }
  }, [_c('em', [_vm._v(_vm._s(_vm.$t('flight.fareNotIncludeTax')))])]), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [!_vm.isEmpty(_vm.resultTripInfo) ? _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "striped": "",
      "body-class": "p-0",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "tbody-class": "text-dark text-nowrap",
      "fields": _vm.tableColumns,
      "items": _vm.resultTripInfo[0].bookingClassAvail
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn
          }, [data.column !== 'priceTemp' ? _c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))]) : _c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))) + " (" + _vm._s(_vm.$t('flight.Adult')) + ")")])])];
        }
      };
    }), {
      key: "cell(classBooking)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-center font-medium-3 font-weight-bolder"
        }, [item.airline && ['VU'].includes(item.airline) ? _c('span', {
          class: !item.refundable ? 'text-danger' : ''
        }, [_vm._v(" " + _vm._s(item.code) + " ")]) : _c('span', [_vm._v(_vm._s(item.code))])])];
      }
    }, {
      key: "cell(fareBasisCode)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-center font-weight-bolder"
        }, [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.getFareAdult(item.prices).fareBasisCode))])])];
      }
    }, {
      key: "cell(availability)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-center font-3 font-weight-bolder",
          class: item.availability === 0 ? 'text-danger' : item.availability > 0 && item.availability < 9 ? 'text-warning' : 'text-success'
        }, [_vm._v(" " + _vm._s(item.availability) + " ")])];
      }
    }, {
      key: "cell(priceTemp)",
      fn: function fn(data) {
        return [!_vm.isEmpty(data.item.prices) && _vm.getFareAdult(data.item.prices) ? _c('p', {
          staticClass: "mb-0 font-weight-bolder",
          attrs: {
            "id": "pax_priceTemp_".concat(data.index)
          }
        }, [['VN'].includes(_vm.resultTripInfo[0].airline) && _vm.resultTripInfo[0].isDomestic ? _c('span', [_vm._v(_vm._s(_vm.$t('flight.netFare')) + ":")]) : _vm._e(), _vm._v(" "), ['VN'].includes(_vm.resultTripInfo[0].airline) ? _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.getFareAdult(data.item.prices).net) + " ")]) : _vm._e(), ['VN'].includes(_vm.resultTripInfo[0].airline) && _vm.resultTripInfo[0].isDomestic ? _c('span', [_vm._v(" - " + _vm._s(_vm.$t('flight.taxes')) + ": "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.getFareAdult(data.item.prices).tax))]), _vm._v(" - " + _vm._s(_vm.$t('flight.total')) + ": "), _c('span', {
          staticClass: "text-warning font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.getFareAdult(data.item.prices).fare))])]) : _vm._e(), _vm.resultTripInfo[0].airline === 'VU' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.total')) + ": "), _c('span', {
          staticClass: "text-warning font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.getFareAdult(data.item.prices).fare))])]) : _vm._e()]) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "id": "tooltip-pax_priceTemp_".concat(data.index),
            "target": "pax_priceTemp_".concat(data.index),
            "triggers": "hover",
            "variant": "info",
            "placement": "top",
            "boundary": "window"
          }
        }, [_c('div', {
          class: "font-italic text-white ".concat(_vm.isMobileView ? 'font-small-1' : 'font-small-3')
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareNotIncludeServiceFee')) + " ")])])];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }